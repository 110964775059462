<template>
  <b-modal v-model="showUserInviteAddModal" centered scrollable title="Invite a User" no-close-on-backdrop no-close-on-esc>
    <b-container fluid class="p-0" style="height: 250px;">
      <b-row>
        <b-col lg="12">
          <ValidationObserver ref="UserInviteForm">
            <form @submit.prevent>
              <div class="form-group mb-0">
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    v-model="vmUserEmail"
                    id="uname"
                    :placeholder="cvUserEmailLabel"/>
                  <span class="text-danger ml-1">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <b-row>
                <b-col class="col-md-6 mb-3">
                  <div>
                    <ValidationProvider name="Mobile Country Code" rules="required|min:2" v-slot="{ errors }">
                      <select v-model="vmUserMobileCountryCode" class="form-control w-100" id="validation_country">
                        <template class="w-100">
                          <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="`+${country.country_phone_code}`">
                            {{country.country_name}} (+ {{country.country_phone_code}})
                          </option>
                        </template>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div>
                    <ValidationProvider name="Mobile" rules="required|min:2" v-slot="{ errors }">
                      <input v-model="vmUserMobile" type="text" class="form-control" required placeholder="Mobile"/>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <multiselect
            v-model="vmSelectedRole"
            :tag-placeholder="ccRoleLabel"
            :placeholder="ccRoleLabel"
            :searchable="true"
            label="udr_name"
            track-by="udr_id"
            :options="getUfdRoleArray()">
          </multiselect>
        </b-col>
      </b-row>
      <b-toast
        v-model="showToast"
        name="Toast"
        :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
        :variant="toastVariant"
        :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closeUserInviteAddModal()">
        Skip
      </b-button>
      <b-button size="sm" variant="primary" @click="userInviteAdd()" :disabled="submitting">
        <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
        </b-spinner>
        <span v-else>Submit</span>
      </b-button>
    </template>
  </b-modal>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { UserRole } from "../../FackApi/api/userRole"
import { User } from "../../FackApi/api/user"
import ApiResponse from "../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import Multiselect from "vue-multiselect"
import { Countries } from "../../FackApi/api/country.js"

export default {
  name: "UserInviteAdd",
  props: {
    "propShowUserInviteAddModal": {
      default: false
    }
  },
  components: {
    ValidationProvider,
    Multiselect
  },
  data () {
    return {
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      cvUserEmailLabel: "Enter user email",
      ccRoleLabel: "Select a role for the user",
      showUserInviteAddModal: false,
      submitting: false,
      vmUserEmail: null,
      vmUserMobileCountryCode: null,
      vmUserMobile: null,
      udfActionObj: {},
      udfActionOptions: [],
      vmSelectedRole: null,
      udfRoleListObj: [],
      selectedUdfRoles: [],
      org_type: "",
      org_id: "",
      cvCountryOptions: []
    }
  },
  mounted () {
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      this.submitting = false
      this.udfActionOptions = []
      this.vmUserEmail = null
      await this.setModuleData()
      await this.udfRoleList()
      await this.countryDistinctList()
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * setModuleData
     */
    async setModuleData () {
      if (this.$route.params.org_id) {
        this.org_type = this.$route.params.org_type
        this.org_id = this.$route.params.org_id
      }
      else if (this.$route.params.club_id) {
        this.org_type = "CLUB"
        this.org_id = this.$route.params.club_id
      }
    },
    /**
     * getUfdRoleArray
     */
    getUfdRoleArray () {
      if (this.udfRoleListObj && Object.keys(this.udfRoleListObj).length > 0) {
        return Object.keys(this.udfRoleListObj).map(key => {
          return this.udfRoleListObj[key]
        })
      }
      return []
    },
    /**
     * userInviteAdd
     */
    async userInviteAdd () {
      let valid = await this.$refs.UserInviteForm.validate()
      if (!valid) {
        return
      }

      this.submitting = true
      let moduleData = {
        user_email: this.vmUserEmail,
        user_mobile_country_code: this.vmUserMobileCountryCode,
        user_mobile: this.vmUserMobile,
        org_type: this.org_type,
        org_id: this.org_id,
        role: this.vmSelectedRole ? JSON.stringify(this.vmSelectedRole) : false
      }
      try {
        let userInviteAddResp = await User.userInviteAdd(this, moduleData)
        await ApiResponse.responseMessageDisplay(this, userInviteAddResp)
        if (userInviteAddResp && !userInviteAddResp.resp_status) {
          this.submitting = false
          return false
        }
        else {
          this.$emit("emitUserInviteAdded", moduleData)
          this.submitting = false
          this.closeUserInviteAddModal()
        }
      }
      catch (err) {
        this.submitting = false
        console.error("Exception occurred at userInviteAdd() and Exception:", err.message)
      }
    },
    /**
     * closeInterestModal
     */
    closeUserInviteAddModal () {
      this.showUserInviteAddModal = false
      this.$emit("emitCloseUserInviteAddModal", true)
    },
    /**
     * udfRoleList
     */
    async udfRoleList () {
      try {
        let udfRoleListResp = await UserRole.udfRoleList(this, this.org_id)
        if (udfRoleListResp && !udfRoleListResp.resp_status) {
          return false
        }
        else {
          this.udfRoleListObj = udfRoleListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at udfRoleList() and Exception:", err.message)
      }
    }
  },
  watch: {
    async propShowUserInviteAddModal () {
      if (this.propShowUserInviteAddModal) {
        await this.loadRequiredData()
        this.showUserInviteAddModal = true
      }
    }
  }
}
</script>
